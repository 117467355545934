import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { SettingsState } from "../../states-models";
import {
  changeAudioDeviceEnumeration,
  changeAudioInputDevice,
  changeAudioOutputDevice,
  changeAudioRingDevice,
  changeIntercomChatOpen,
  changeDoNotDisturb,
  changeLanguage,
  changeCallWaiting,
  changeUpdateDownloaded,
} from "../../actions";
import {
  selectAudioDataDevice,
  selectAudioDeviceEnumeration,
  selectAudioInputDevice,
  selectAudioOutputDevice,
  selectAudioRingDevice,
  selectCallWaiting,
  selectDoNotDisturb,
  selectIntercomChatOpen,
  selectLanguage,
  selectSettingsState,
  selectUpdateDownloaded,
} from "../../selectors/settings/settings.selector";

@Injectable()
export class SettingsFacade {
  settings$ = this.store.pipe(select(selectSettingsState));
  language$ = this.store.pipe(select(selectLanguage));
  doNotDisturb$ = this.store.pipe(select(selectDoNotDisturb));
  callWaiting$ = this.store.pipe(select(selectCallWaiting));
  audioDeviceEnumeration$ = this.store.pipe(select(selectAudioDeviceEnumeration));
  audioInputDevice$ = this.store.pipe(select(selectAudioInputDevice));
  audioOutputDevice$ = this.store.pipe(select(selectAudioOutputDevice));
  audioRingDevice$ = this.store.pipe(select(selectAudioRingDevice));
  audioDataDevices$ = this.store.pipe(select(selectAudioDataDevice));
  intercomChatOpen$ = this.store.pipe(select(selectIntercomChatOpen));
  updateDownloaded$ = this.store.pipe(select(selectUpdateDownloaded));

  /**
   * The constructor get only the store about the settings
   * @param store Store of the settings
   */
  constructor(private store: Store<SettingsState>) { }

  /**
   * Change the language in the store (should be used by the i18n service).
   * @param language The language to set in the store.
   */
  setLanguage(language: string) {
    this.store.dispatch(changeLanguage(language));
  }

  /**
   * Set the Audio Device Enumaration in the store.
   * @param {MediaDeviceInfo[]} enumeration The list of media device to use
   */
  setAudioDeviceEnumeration(enumeration: MediaDeviceInfo[]) {
    this.store.dispatch(changeAudioDeviceEnumeration(enumeration));
  }

  /**
   * Set the Audio Input Device in the store.
   * @param {MediaDeviceInfo} device The device to use for input
   */
  setAudioInputDevice(device: MediaDeviceInfo) {
    this.store.dispatch(changeAudioInputDevice(device));
  }

  /**
   * Set the Audio Output Device in the store.
   * @param {MediaDeviceInfo} device The device to use for output
   */
  setAudioOutputDevice(device: MediaDeviceInfo) {
    this.store.dispatch(changeAudioOutputDevice(device));
  }

  /**
   * Set the Audio Ring Device in the store.
   * @param {MediaDeviceInfo} device The device to use for ringing
   */
  setAudioRingDevice(device: MediaDeviceInfo) {
    this.store.dispatch(changeAudioRingDevice(device));
  }

  /**
  * Set the Intercom Chat Open in the store.
  * @param {boolean} value The use to set intercom chat open or not
  */
  setIntercomChatOpen(value: boolean) {
    this.store.dispatch(changeIntercomChatOpen(value));
  }

  setDoNotDisturb(dnd: boolean) {
    this.store.dispatch(changeDoNotDisturb(dnd));
  }

  setCallWaiting(callWaiting: boolean) {
    this.store.dispatch(changeCallWaiting(callWaiting));
  }

  setUpdateDownloaded(updateDownloaded: boolean) {
    this.store.dispatch(changeUpdateDownloaded(updateDownloaded));
  }
}
