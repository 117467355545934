import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';
import { InviteEmail } from '../../models/referral.model';

@Injectable()
export class ReferralService {

  constructor(private http: HttpClient) { }
  
  sendInviteEmail(data: InviteEmail): Observable<any> {
    return this.http.post<any>(`${AppConfig.endpointURL}/v1/referrers/invite-email`, data);
  }
}