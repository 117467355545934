import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'secondstominutes' })
export class SecondsToMinutesPipe implements PipeTransform {

  transform(value: number): string {
    let v = Math.floor(value);
    const hours = v > 3600 ? `${Math.floor(v / 3600)}`.padStart(2, '0') : '';
    v %= 3600;
    const minutes = `${Math.floor(v / 60)}`.padStart(2, '0');
    const seconds = `${Math.floor(value) % 60}`.padStart(2, '0');
    return hours ? `${hours} : ${minutes} : ${seconds}` : `${minutes} : ${seconds}`;
  }
}