import { Injectable } from '@angular/core';
import { ConfigService } from '../../../services/config';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as SettingsActions from '../../actions';
import { AudioService, SipService } from '../../../services';


@Injectable()
export class SettingsEffects {

  constructor(
    private action$: Actions,
    private configService: ConfigService,
    private audioService: AudioService,
    private sipService: SipService
  ) { }

  /**
   * Reflect the change in audio input device in the config service which should the saved to disk
   */
  changeAudioInputConfig$ = createEffect(() => this.action$.pipe(
    ofType(SettingsActions.changeAudioInputDevice),
    map(action => {
      this.sipService.audioDeviceConstraints = action.inputDevice;
      this.configService.config = {
        ...this.configService.config,
        settings: {
          ...this.configService.config.settings,
          audio: {
            ...this.configService.config.settings.audio || {},
            audio_input_device: JSON.stringify(action.inputDevice)
          }
        }
      }
    })
  ), { dispatch: false });

  /**
   * Reflect the change in audio input device in the config service which should the saved to disk
   */
  changeAudioOutputConfig$: Observable<void> = createEffect(() => this.action$.pipe(
    ofType(SettingsActions.changeAudioOutputDevice),
    map(action => {
      this.audioService.setOutputAudio(action.outputDevice);
      this.configService.config = {
        ...this.configService.config,
        settings: {
          ...this.configService.config.settings,
          audio: {
            ...this.configService.config.settings.audio || {},
            audio_output_device: JSON.stringify(action.outputDevice)
          }
        }
      }
    })
  ), { dispatch: false });

  /**
   * Reflect the change in audio input device in the config service which should the saved to disk
   */
  changeAudioRingConfig$: Observable<void> = createEffect(() => this.action$.pipe(
    ofType(SettingsActions.changeAudioRingDevice),
    map(action => {
      this.audioService.setRingAudio(action.ringDevice);
      this.configService.config = {
        ...this.configService.config,
        settings: {
          ...this.configService.config.settings,
          audio: {
            ...this.configService.config.settings.audio || {},
            audio_ring_device: JSON.stringify(action.ringDevice)
          }
        }
      }
    })
  ), { dispatch: false });

  changeDoNotDisturb$: Observable<void> = createEffect(() => this.action$.pipe(
    ofType(SettingsActions.changeDoNotDisturb),
    map(action => {
      this.sipService.dndStatus = action.dnd;
    })
  ), { dispatch: false });
}