import { Injectable } from '@angular/core';
import { ContextMenuTypes, IPC_CHANNELS } from '../../../../../electron-utils/electron.model';
import { ElectronService } from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private electronService: ElectronService) { }

  /**
   * If electron is present, open the page in the default browser
   * otherwise open the page in a new tab
   * @param {string} link Link to open
   */
  public openExternalLink(link: string): void {
    //if we are on electron, use electron to open the link on the default browser
    //otherwise use
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send(IPC_CHANNELS.OPEN_EXTERNAL_LINK, link);
    } else {
      window.open(link, '_blank');
    }
  }

  public detectBrowser() {
    var userAgent = navigator.userAgent;
    var browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i};
    for(var key in browsers) {
        if (browsers[key].test(userAgent)) {
            return key;
        }
    };
    return 'unknown';
  }

  public copyToClipboard(value: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public replaceExternalPopupKeys(externalLink: string, number: string) {
    return externalLink.replace("${CALLER_ID}", number);
  }

  public getContextMenuType(event: MouseEvent) {
    const element = document.elementFromPoint(event.x, event.y);
    if(!element || element === document.documentElement) return null;
    return element.tagName === 'INPUT' ? ContextMenuTypes.input : ContextMenuTypes.selection;
  }

  public getTextSelected() {
    if(window.getSelection().type === 'Range' && window.getSelection().toString().length > 0) {
      return window.getSelection().toString();
    } else {
      return null;
    }
  }

  public pasteInPosition(original: string, pasteData: string, selectionStart: number, selectionEnd: number){
    if(typeof original !== 'string') original = '';
    return original.slice(0, selectionStart) +
           pasteData +
           original.slice(selectionEnd);
  }

  public isValidPhoneNumber(number: string): boolean {
    return /^(\+)?\d+$/.test(number);
  }

  public cleanNumber(dirtyNumber: string): string {
    let startWithPlus: boolean = false;
    let cleanNumber = dirtyNumber;
    if(cleanNumber.startsWith('+')) startWithPlus = true;
    cleanNumber = cleanNumber.trim();
    cleanNumber = cleanNumber.replace(/[-+\s\(\)]*/gm, '');
    if(startWithPlus) cleanNumber = '+' + cleanNumber;
    return cleanNumber;
  }
}
