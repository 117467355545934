import { Component, Input, OnInit } from '@angular/core';
import { CallHistoryRecord, CallHistoryRecordType } from '../../../core/services';


@Component({
  selector: 'app-call-direction-icon',
  template: `
    <i [ngClass]="{ 
      'icon-small-call-inbound': record.type === callHistoryRecordType.INCOMING_ANSWERED || record.type === callHistoryRecordType.INCOMING_ANSWERED_ELSEWHERE,
      'icon-small-call-outbound': record.type === callHistoryRecordType.OUTGOING_ANSWERED  || record.type === callHistoryRecordType.OUTGOING_MISSED,
      'icon-small-call-lost': record.type === callHistoryRecordType.INCOMING_MISSED,
      'icon-bright-crimson': record.type === callHistoryRecordType.INCOMING_MISSED
    }"></i>`,
  styles: [`
    i {
      font-size: 24px;
    }
    .icon-bright-crimson {
    color: #ff3465;
    }
  `]
})
export class CallDirectionIconComponent implements OnInit {
  @Input() record: CallHistoryRecord;
  public callHistoryRecordType = CallHistoryRecordType;

  ngOnInit(): void { }
}