import { Injectable, OnDestroy } from '@angular/core';
import { Invitation, RegistererState } from 'sip.js';
import { SipCredentials } from '../../../account/store/states-models';
import { SipJsStoreService } from './sipjs.store.service'
import { SimpleCallUAOptions, UserAgentWrapperOptions } from '../../sipjs/models';
import { SessionWrapper, UAWrapper } from '../../sipjs/wrappers';
import { SessionManagerService } from './sessions-manager.service';
import { PresenceModel, SIP_STATUS } from './sip.models';
import { Logger, LoggerService } from '../logger';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PresenceService } from './presence.service';
import { ElectronService } from '../electron';
import { IPC_CHANNELS } from '../../../../../electron-utils/electron.model';
import { AUDIO_TYPE, AudioService } from './audio.service';
import { IncomingResponse } from 'sip.js/lib/core';



/**
 * This service should take care of the interaction between
 * the varius sip wrapper and the app, offering a simple interface to do the main
 * operations.
 */

@Injectable({ providedIn: 'root' })
export class SipService implements OnDestroy {

  private logger: Logger;
  private subscriptions: Subscription[] = [];
  public sipStatus$: BehaviorSubject<SIP_STATUS> = new BehaviorSubject(SIP_STATUS.OFFLINE);
  public currentDndStatus: boolean = false;
  public currentStatus: SIP_STATUS = SIP_STATUS.OFFLINE;

  private audioConstraints: MediaTrackConstraints;

  constructor(
    private sipStore: SipJsStoreService,
    private sessionManager: SessionManagerService,
    private loggerService: LoggerService,
    private presenceService: PresenceService,
    private electronService: ElectronService,
    private audioService: AudioService
  ) {
    this.logger = loggerService.getLoggerInstance('SipService');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
    this.logger.close();
  }

  /**
   * Factory to create and store the new user agent
   * @param sipCreds SipCredentials object
   * @param options Options used to create the user agent
   */
  public createAndStoreUserAgent(sipCreds: SipCredentials, options?: UserAgentWrapperOptions) {
    options = options || {};
    options.onInviteDelegate = this.onInviteDelegate;
    options.onConnectDelegate = () => {
      this.changeSipStatus(SIP_STATUS.ONLINE)
      this.presenceService.restartSubscriptions();
    };
    options.onDisconnectDelegate = () => {
      this.changeSipStatus(SIP_STATUS.OFFLINE);
    };
    options.onRegistered = () => {
      this.changeSipStatus(SIP_STATUS.ONLINE);
    };
    options.onReconnectingDelegate = () => {
      this.changeSipStatus(SIP_STATUS.RECONNECTING);
    }
    options.onFailedToReconnectDelegate = () => {
      this.changeSipStatus(SIP_STATUS.FAILED_TO_RECONNECT);
    }
    const ua = new UAWrapper(sipCreds, this.loggerService, this.sessionManager, options);
    this.sipStore.userAgentWrapper = ua;
  }

  /**
   * Call the target
   * @param target Target to call
   * @param options Options fpr the new session
   */
  public call(target: string, options?: SimpleCallUAOptions) {
    options = options || {};
    options.simpleSessionCallOptions = options.simpleSessionCallOptions || {};
    if(!options.simpleSessionCallOptions.inviteOptions) {
      options.simpleSessionCallOptions.inviteOptions = {};
    }
    if(!options.simpleSessionCallOptions.inviteOptions.delegates) {
      options.simpleSessionCallOptions.inviteOptions.delegates = {};
    }
    options.simpleSessionCallOptions.inviteOptions.constraints = this.audioConstraints;
    options.simpleSessionCallOptions.inviteOptions.delegates.onTryingDelegate = this.onInviteTryingDelegate;
    options.simpleSessionCallOptions.inviteOptions.delegates.onProgressDelegate = this.onInviteProgressDelegate;
    const ua = this.sipStore.userAgentWrapper;
    return this.sessionManager.addSession(ua.call(target, options));
  }

  /**
   * Callback to execute when the INVITE arrive
   * @param invitation Session created with the received INVITE
   */
  private onInviteDelegate = (invitation: Invitation) => {
    // Add the stat listener to the session to enable ringing and attaching media
    invitation.sessionDescriptionHandlerOptions.constraints = this.audioConstraints;
    this.sessionManager.addSession(new SessionWrapper(invitation, this.loggerService), true);
  }

  /**
   * Get the registration state from the user agent
   * @returns Returns the registrationState
   */
  public get status(): string {
    return this.sipStore.userAgentWrapper.registrationState;
  }

  /**
   * Set the constraint for audio
   * @param audioDeviceInput The media device info to use as constraint
   */
  public set audioDeviceConstraints(audioDeviceInput: MediaDeviceInfo) {
    if(audioDeviceInput) {
      this.audioConstraints = {
        deviceId: {
          ideal: audioDeviceInput.deviceId
        }
      }
    };
  }

  /**
   * Change the sip status and forward the event
   * @param dndStatus Flag to set Do not Disturb
   */
  public changeSipStatus(status: SIP_STATUS = null) {
    let stat: SIP_STATUS = SIP_STATUS.OFFLINE;
    if(!status) {
      status = this.currentStatus;
    } else {
      this.currentStatus = status;
    }
    const isUARegistered = this.sipStore.userAgentWrapper.registrationState === RegistererState.Registered;

    if(status === SIP_STATUS.ONLINE && isUARegistered) {
      stat = this.currentDndStatus ? SIP_STATUS.DND : SIP_STATUS.ONLINE;
    } else {
      stat = status;
    }

    this.sipStatus$.next(stat);
    if(this.electronService.isElectron) {
      this.electronService.ipcRenderer.send(IPC_CHANNELS.SET_STATUS, stat);
    }

    this.logger.debug(`App status transitioned to: ${stat}`);
  }

  /**
   * Callback to play the connection sound when the invite session start
   * @param response Incoming response message from invite
   */
  private onInviteTryingDelegate = (response: IncomingResponse) => {
    this.audioService.play(AUDIO_TYPE.CONNECTION);
  }

  /**
   * Callback to play the outgoing ring sound when the invite session transition to 183 progress
   * @param response Incoming response message from invite
   */
  private onInviteProgressDelegate = (response: IncomingResponse) => {
    this.audioService.stop(AUDIO_TYPE.CONNECTION);
    this.audioService.play(AUDIO_TYPE.OUTGOING_RING);
  }

  public set dndStatus(dnd: boolean) {
    this.currentDndStatus = dnd;
    this.changeSipStatus();
  }

  public forceReconnect(): void {
    this.sipStore.userAgentWrapper.forceReconnection();
  }
}