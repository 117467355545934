import { Injectable } from '@angular/core';
import { Logger } from './logger.class';
import { ERROR_LEVELS } from './logger.model';
// Fake logger for mocking tests

export class LoggerFakeService {
  constructor() { }

  getLoggerInstance(componentName: string, errorLevel?: ERROR_LEVELS): Logger | undefined {
    return <Logger>new LoggerFake(componentName, errorLevel);
  }
}

export class LoggerFake {
  constructor(
    componentName: string,
    errorLevel: ERROR_LEVELS,
  ) { }

  public silly(...args) { }
  public debug(...args) { }
  public verbose(...args) { }
  public info(...args) { }
  public http(...args) { }
  public warn(...args) { }
  public error(...args) { }

  public close() { }
}