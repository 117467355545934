import { Component, OnDestroy, OnInit } from '@angular/core';
import { SIP_STATUS, SipService } from '../../../core/services';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reconnection-modal',
  templateUrl: './reconnection-modal.component.html',
  styleUrls: ['./reconnection-modal.component.scss']
})
export class ReconnectionModalComponent implements OnInit, OnDestroy {

  private statusSub: Subscription;

  public isReconnecting: boolean = false;

  constructor(
    private modalRef: NgbActiveModal,
    private sipService: SipService
  ) { }

  ngOnInit(): void {
    this.statusSub = this.sipService.sipStatus$.subscribe((status: SIP_STATUS) => {
      switch(status){
        case SIP_STATUS.DND:
        case SIP_STATUS.ONLINE:
          this.isReconnecting = false;
          this.modalRef.close();
          break;
        case SIP_STATUS.RECONNECTING:
          this.isReconnecting = true;
          break;
        default:
          this.isReconnecting = false;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.statusSub?.unsubscribe();
  }

  public forceReconnect(): void {
    this.sipService.forceReconnect();
  }

  public close(): void {
    this.modalRef.close();
  }

}
