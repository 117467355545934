export interface AskConfirmationOptions {
  titleToken?: string;
  messageToken?: string;
  confirmToken?: string;
  cancelToken?: string;
  messageParameters?: {
    [key: string]: any
  }
}

export const defaultOptions: AskConfirmationOptions = {
  titleToken: 'common.notification.ask-confirm.title',
  messageToken: 'common.notification.ask-confirm.message',
  confirmToken: 'common.notification.ask-confirm.confirm',
  cancelToken: 'common.notification.ask-confirm.cancel',
  messageParameters: {}
}