import { createAction, union } from "@ngrx/store";

/**
 * Change language action
 * @param language New language
 */
export const changeLanguage = createAction(
  "[Settings] Change Language",
  (language: string) => ({ language })
);

/**
 * Change audio device enumeration action
 * @param {MediaDeviceInfo[]} enumeration New ringtone to use
 */
export const changeAudioDeviceEnumeration = createAction(
  "[Settings] Change Audio Device Enumeration",
  (enumeration: MediaDeviceInfo[]) => ({ enumeration })
);

/**
 * Change audio input device action
 */
export const changeAudioInputDevice = createAction(
  "[Settings] Change Audio Input Device",
  (inputDevice: MediaDeviceInfo) => ({ inputDevice })
);

/**
 * Change audio output device action
 */
export const changeAudioOutputDevice = createAction(
  "[Settings] Change Audio Output Device",
  (outputDevice: MediaDeviceInfo) => ({ outputDevice })
);

/**
 * Change audio ring device action
 */
export const changeAudioRingDevice = createAction(
  "[Settings] Change Audio Ring Device",
  (ringDevice: MediaDeviceInfo) => ({ ringDevice })
);

/**
 * Change intercom chat action
 */
export const changeIntercomChatOpen = createAction(
  "[Settings] Change Intercom Chat Open",
  (intercom_chat_open: boolean) => ({ intercom_chat_open })
);

export const changeDoNotDisturb = createAction(
  "[Settings] Change Do Not Disturb",
  (dnd: boolean) => ({ dnd })
);

export const changeCallWaiting = createAction(
  "[Settings] Change Call Waiting",
  (callWaiting: boolean) => ({callWaiting})
);

export const changeUpdateDownloaded = createAction(
  "[Settings] Change Updated Downloaded",
  (updateDownloaded: boolean) => ({updateDownloaded})
)

const actions = {
  changeLanguage,
  changeAudioDeviceEnumeration,
  changeAudioInputDevice,
  changeAudioOutputDevice,
  changeAudioRingDevice,
  changeIntercomChatOpen,
  changeDoNotDisturb,
  changeCallWaiting,
  changeUpdateDownloaded
};

const settingsActions = union(actions);

export type SettingsAction = typeof settingsActions;
