import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: [
    '../../../shared/styles/contact-list/contact-list.scss',
    './favorite-icon.component.scss'
  ]
})
export class FavoriteIconComponent implements OnInit {

  @Input() isFavorite: boolean = false;

  public isHovering: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
