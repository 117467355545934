import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CallHistoryRecordType } from '../../core/services';

@Pipe({ name: 'callHistoryType' })
export class CallHistoryTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(value: CallHistoryRecordType): string {
    let token: string;
    switch(value) {
      case CallHistoryRecordType.INCOMING_ANSWERED:
      case CallHistoryRecordType.INCOMING_ANSWERED_ELSEWHERE: 
        token = 'events.call-history-table.status.inbound';
        break;
      case CallHistoryRecordType.OUTGOING_ANSWERED:
      case CallHistoryRecordType.OUTGOING_MISSED:
        token = 'events.call-history-table.status.outbound';
        break;
      case CallHistoryRecordType.INCOMING_MISSED:
        token = 'events.call-history-table.status.missed';
        break;
      default: break;
    }
    return this.translate.instant(token);
  }
}
