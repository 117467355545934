import { Injectable } from '@angular/core';
import { UAWrapper } from '../../../core/sipjs/wrappers';


@Injectable({ providedIn: 'root' })
export class SipJsStoreService {
  private _userAgentWrapper: UAWrapper;

  /**
   * Getter for the user agent wrapper
   * @returns {UAWrapper} Returns the user agent wrapper
   */
  public get userAgentWrapper(): UAWrapper {
    return this._userAgentWrapper;
  }

  /**
   * Setter for the user agent wrapper
   * @param {UAWrapper} ua The user agent we want to store
   */
  public set userAgentWrapper(ua: UAWrapper) {
    // Clean the old before assigning new UA
    if (this._userAgentWrapper) this._userAgentWrapper.destroy();
    this._userAgentWrapper = ua;
  }


}