import { Injectable } from "@angular/core";
import { RouterStateSerializer } from "@ngrx/router-store";
import { RouterStateSnapshot } from "@angular/router";
import { RouterState } from "../states-models/router.state.model";

@Injectable()
export class RouterSerializer implements RouterStateSerializer<RouterState> {
  /**
   * Implementation of serialize used to serialize the RouterStateSnapshot in a smaller version.
   * @param routerState Router state snapshot
   */
  serialize(routerState: RouterStateSnapshot) {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    return { url, params, queryParams };
  }
}
