import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AskConfirmationOptions, defaultOptions } from './confirmation.model';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  public options: AskConfirmationOptions;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.options = {
      ...defaultOptions,
      ...this.options
    }
  }

  public ok(): void {
    this.activeModal.close('ok')
  }

  public close(): void {
    this.activeModal.close('cancel')
  }

}
