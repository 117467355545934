import { Component, OnInit } from '@angular/core';
import { IPC_CHANNELS } from '../../../../electron-utils/electron.model';
import { ElectronService, IncomingCallElectronModel } from '../../core/services';

@Component({
  selector: 'incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss']
})
export class IncomingCallComponent implements OnInit {

  public call: IncomingCallElectronModel;

  constructor(
    private electronService: ElectronService
  ) { }

  ngOnInit(): void {
    this.call = JSON.parse(
      window.history.state.args.find((a: string) => a.includes("callId"))
    );
    this.electronService.ipcRenderer.on(IPC_CHANNELS.UPDATE_INCOMING_CALL, (event, ...args: any[]) => {
      if(args[0]) this.call.fullname = args[0];
    });
  }

  public onAccept(): void {
    if(this.electronService.isElectron) {
      this.electronService.ipcRenderer.send(IPC_CHANNELS.ACCEPT_INCOMING_CALL, this.call.callId);
    }
  }

  public onReject(): void {
    if(this.electronService.isElectron) {
      this.electronService.ipcRenderer.send(IPC_CHANNELS.REJECT_INCOMING_CALL, this.call.callId);
    }
  }

}
