import { IDBPDatabase } from "idb";
import { AddressbookIndexes, DBStores, DatabaseSchema } from "./database.model";
import { ADDRESSBOOK_TYPES, FavoriteContactType } from "../addressbook/addressbook.model";

export const DBMigrationMethods = [
  // from -1 to 0
  null,
  // from 0 to 1 
  null,
  // from 1 to 2 (index 2)
  async (db: IDBPDatabase<DatabaseSchema>, oldVersion: number, newVersion: number, transaction: any) => {
    if (transaction && db.objectStoreNames.contains(DBStores.ADDRESSBOOK_OS)) {
      const objstore = transaction.objectStore(DBStores.ADDRESSBOOK_OS);
      const favObjStore = transaction.objectStore(DBStores.FAVORITES_OS);
      const idx = objstore.index(AddressbookIndexes.BY_TYPE);
      let cursor = await idx.openCursor()
      while (cursor) {
        const v = cursor.value;
        if(v.favorite){
          switch(v.type) {
            case ADDRESSBOOK_TYPES.EXTERNAL:
              favObjStore.add({
                contactId: String(v.id),
                type: FavoriteContactType.LOCAL
              });
              break;
            case ADDRESSBOOK_TYPES.INTERNAL_USER:
            case ADDRESSBOOK_TYPES.INTERNAL_GROUP:
              favObjStore.add({
                contactId: String(v.id),
                type: FavoriteContactType.INTERNAL
              });
              break;
          }
        }
        if (cursor.key == 'external') {
          v.type = ADDRESSBOOK_TYPES.LOCAL;
          cursor.update(v);
        }
        cursor = await cursor.continue();
      };
    }
  }
]
