import { ErrorHandler, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { ERROR_LEVELS, Logger, LoggerService } from '../logger';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {

  private logger: Logger;

  constructor(@Inject(Injector) private injector: Injector) {
  }

  handleError(error: Error) {
    if(!this.logger)
      this.logger = this.loggerService.getLoggerInstance('GlobalErrorHandler', ERROR_LEVELS.ERROR);

    this.logger.globalError(error.stack ? error.stack : { ...[error.name, error.message] }); // log the error
    throw error; // Pass the error over. Error in console will be displayed twice but
  }

  private get loggerService(): LoggerService {
    return this.injector.get(LoggerService);
  }

  ngOnDestroy(): void {
    this.logger.close();
  }
}