import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialerButtonValue } from './dialer-button.model';

@Component({
  selector: 'dialer-button',
  templateUrl: './dialer-button.component.html',
  styleUrls: ['./dialer-button.component.scss']
})
export class DialerButtonComponent implements OnInit {

  @Input() key: DialerButtonValue = null;

  @Output() buttonPressed: EventEmitter<DialerButtonValue> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onButtonPressed(): void {
    this.buttonPressed.emit(this.key);
  }

}
