import { Component, OnInit } from '@angular/core';
import { InAppNotification } from '../../notification.model';
import { NotificationService } from '../in-app.notification.service';

@Component({
  selector: 'in-app-notification',
  templateUrl: './in-app-notification.component.html',
  styleUrls: ['./in-app-notification.component.scss']
})
export class InAppNotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  /**
   * Close and remove a notification
   * @param {InAppNotification} notification Notification to close
   */
  public close(notification: InAppNotification) {
    this.notificationService.remove(notification);
  }
}
