import { BehaviorSubject, Subject } from 'rxjs';
import { SessionWrapper } from '../../sipjs/wrappers';
import { CallHistoryRecord } from '../callhistory';

export interface SessionContainer {
  session: SessionWrapper;
  meta: any;
  type: SESSION_TYPE;
  changeTypeListener: Subject<SESSION_TYPE>;
  callHistoryRecord: CallHistoryRecord;
  updateCallHistory: (record: CallHistoryRecord) => void;
  onCallHistoryCreated: BehaviorSubject<boolean>
}

export enum SESSION_TYPE {
  UNDEFINED = 'undefined',
  CONNECTING = 'connecting',
  ANSWERED = 'answered',
  WAITING = 'waiting',
  TERMINATED = 'terminated'
}

export const MAX_SESSIONS: number = 3;
export const MAX_WAITING: number = 3;

// Using any we have a two way enum, so that we can get the value from the key
// and the key from the value
export enum PRESENCE_STATUS {
  ON_THE_PHONE = 'on the phone',
  RINGING = 'ringing',
  AVAILABLE = 'available',
  ONLINE = 'online',
  OFFLINE =  'offline',
  DND = 'dnd',
  NONE = 'none'
}

export const PRESENCE_STATUSES_LIST: string[] = [
  'on the phone',
  'ringing',
  'available',
  'online',
  'offline',
  'dnd',
  'none'
]

export interface PresenceModel {
  entity: string;
  status: PRESENCE_STATUS;
}

export enum SIP_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  REGISTERED = 'registered',
  REGISTERING = 'registering',
  RECONNECTING = 'reconnecting',
  FAILED_TO_RECONNECT = 'failed_to_reconnect',
  DND = 'dnd'
};