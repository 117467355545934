import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../../environments/environment';
import { AccountFacade } from '../../../account/store/facade';
import { SubscriptionLite } from '../../../account/store/states-models';
import { Integration } from '../../models/integrations.model';

@Injectable()
export class IntegrationsService {
  public companyId: string;
  private shortlyDeletedGuid: string[] = [];
  
  constructor(
    private http: HttpClient,
    private accountFacade: AccountFacade
  ) {
    accountFacade.subscription$.subscribe((sub: SubscriptionLite) => {
      this.companyId = sub.company_id;
    });
  }

  
  private wasGuidDeleted(guid: string) {
    if (guid) return this.shortlyDeletedGuid.includes(guid);
    return false;
  }

  public getEnabledIntegrations() {
    return this.http.get(`${AppConfig.blendrApiUrl}/accounts/${this.companyId}/blends`).pipe(
      map((res: any) => {
        let simpleData: string[] = [];
        res.data.forEach((template: Integration) => {
          if (
            template.setup_complete &&
            template.isbundle &&
            !this.wasGuidDeleted(template.guid)
          ) {
            simpleData.push(template.name);
          }
        });
        return simpleData;
      })
    )
  }
}