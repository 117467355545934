import { CONSOLE_COLORS, ERROR_LEVELS, ERROR_SEVERITY } from './logger.model';

/**
 * This object is returned by the logger service to each component that request it
 * It serves as the log object that will write to console and to winston logger
 * 
 */
export class Logger {
  private _winstonLogger: any;            // Winston logger instance
  private _componentName: string;         // Component name for log prefix
  private _errorLevel: ERROR_LEVELS;      // Minumum error level
  private _logWinston: boolean;           // Flag to log also in winston

  constructor(
    componentName: string,
    errorLevel: ERROR_LEVELS,
    winstonLogger: any = null,
    logWinston: boolean = true
  ) {
    this._winstonLogger = winstonLogger;
    this._componentName = componentName;
    this._errorLevel = errorLevel;
    this._logWinston = logWinston;
  }

  /**
   * Keeping log line means to pratically return a console because the line number is collected when the console method is called.
   * A common way is to use console.log.bind(console.log, prefix) to attach some string and then return the console.
   * This method cannot be applied in this case because we need the arguments for the winston logger.
   * Until another way is found, we lose the line where the log is called.
   */

  /**
   * Log messages on silly level
   * @param {any} args List of args to pass to the logger
   */
  public silly(...args) {
    // If error level is important enough, log the message (errors go from 0 to 6, more important to less)
    if (this.canBeLogged(ERROR_LEVELS.SILLY)) {
      // Winston logger accept only strings as message, that's why the elements of args are joined with '; ' separator
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.silly(this.stringifyArguments(args).join('; '), null);
      // This is to color the component and level for a better visibility in chromium console.
      console.log(this.colorPrefix(ERROR_LEVELS.SILLY), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on debug level
   * @param {any} args List of args to pass to the logger
   */
  public debug(...args) {
    if (this.canBeLogged(ERROR_LEVELS.DEBUG)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.debug(this.stringifyArguments(args).join('; '), null);
      console.debug(this.colorPrefix(ERROR_LEVELS.DEBUG), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on verbose level
   * @param {any} args List of args to pass to the logger
   */
  public verbose(...args) {
    if (this.canBeLogged(ERROR_LEVELS.VERBOSE)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.verbose(this.stringifyArguments(args).join('; '), null);
      console.log(this.colorPrefix(ERROR_LEVELS.VERBOSE), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on http level
   * @param {any} args List of args to pass to the logger
   */
  public http(...args) {
    if (this.canBeLogged(ERROR_LEVELS.HTTP)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.http(this.stringifyArguments(args).join('; '), null);
      console.debug(this.colorPrefix(ERROR_LEVELS.HTTP), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on info level
   * @param {any} args List of args to pass to the logger
   */
  public info(...args) {
    if (this.canBeLogged(ERROR_LEVELS.INFO)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.info(this.stringifyArguments(args).join('; '), null);
      console.info(this.colorPrefix(ERROR_LEVELS.INFO), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on warning level
   * @param {any} args List of args to pass to the logger
   */
  public warn(...args) {
    if (this.canBeLogged(ERROR_LEVELS.WARN)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.warn(this.stringifyArguments(args).join('; '), null);
      console.warn(this.colorPrefix(ERROR_LEVELS.WARN), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Log messages on error level
   * @param {any} args List of args to pass to the logger
   */
  public error(...args) {
    if (this.canBeLogged(ERROR_LEVELS.ERROR)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.error(this.stringifyArguments(args).join('; '), null);
      console.error(this.colorPrefix(ERROR_LEVELS.ERROR), CONSOLE_COLORS.UNSET, CONSOLE_COLORS.BLUE, CONSOLE_COLORS.UNSET, CONSOLE_COLORS.GREEN, CONSOLE_COLORS.UNSET, ...args);
    }
  }

  /**
   * Similar to error but will not print on console. TO USE ONLY IN GLOBAL ERROR HANDLER
   * @param {any} args List of args to pass to the logger
   */
  public globalError(...args) {
    if (this.canBeLogged(ERROR_LEVELS.ERROR)) {
      if (this._winstonLogger && this._logWinston)
        this._winstonLogger.error(this.stringifyArguments(args).join('; '), null);
    }
  }

  /**
   * Close and free logger memory
   */
  public close(): void {
    this._winstonLogger?.close();
  }

  /**
   * Check if the error level is minor or equal to the max error level allowed (error levels goes from 0 to 6, most important to less)
   * @param {ERROR_LEVELS} methodLogLevel Log level of the method to check
   * @return {boolean} True if the log level is low enough to be logged, false otherwise
   */
  private canBeLogged(methodLogLevel: ERROR_LEVELS): boolean {
    return ERROR_SEVERITY[this._errorLevel] >= ERROR_SEVERITY[methodLogLevel];
  }

  private colorPrefix(level: ERROR_LEVELS): string {
    return '%c[' + `%c${this._componentName}` + '%c] [' + `%c${level.toUpperCase()}` + '%c]';
  }

  private stringifyArguments(args: any[]): any {
    // Stringify strings will just add a "" so for better readability let's avoid stringify them.
    return args.map(a => typeof a === 'string' ? a : JSON.stringify(a));
  }
}