export const BUTTON_TYPE: MainActionTypes = {
  "dialer": {
    backgroundClass: 'crimson-bg',
    iconClass: 'dai-buttons'
  },
  "call": {
    backgroundClass: 'success-bg',
    iconClass: 'dai-call'
  },
  "hangup": {
    backgroundClass: 'danger-bg',
    iconClass: 'dai-endcall'
  },
  "back": {
    backgroundClass: 'white-bg',
    iconClass: 'dai-backarrow'
  }
}

export interface MainActionClasses {
  backgroundClass: string;
  iconClass: string;
}

export interface MainActionTypes {
  [key: string]: MainActionClasses;
}