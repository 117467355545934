import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialerButtonValue, FULL_DIALER } from '../dialer-button/dialer-button.model';
import { AUDIO_TYPE, AudioService } from '../../../core/services';

const dtmfMap = {
  0: AUDIO_TYPE.DTMF_0,
  1: AUDIO_TYPE.DTMF_1,
  2: AUDIO_TYPE.DTMF_2,
  3: AUDIO_TYPE.DTMF_3,
  4: AUDIO_TYPE.DTMF_4,
  5: AUDIO_TYPE.DTMF_5,
  6: AUDIO_TYPE.DTMF_6,
  7: AUDIO_TYPE.DTMF_7,
  8: AUDIO_TYPE.DTMF_8,
  9: AUDIO_TYPE.DTMF_9,
  '#': AUDIO_TYPE.DTMF_STAR,
  '*': AUDIO_TYPE.DTMF_POUND
}



@Component({
  selector: 'dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit {

  @Input() enableSounds: boolean = true;
  @Output() keyPressed: EventEmitter<string> = new EventEmitter();

  public fullDialer = FULL_DIALER;
  private audio = new Audio();

  constructor(
    private audioService: AudioService
  ) { }

  ngOnInit(): void {
  }

  public onKeyPressed(key: DialerButtonValue): void {
    if(this.enableSounds){
      this.audioService.playAndStop(dtmfMap[key.key]);
    }
    this.keyPressed.emit(key.key);
  }

}
