import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SessionState } from 'sip.js';
import { SIP_STATUS_CODES } from '../../sipjs/models';
import { SessionWrapper } from '../../sipjs/wrappers';
import { SettingsFacade } from '../../store/facade';
import { CallHistoryRecord, CallHistoryRecordType } from '../callhistory';
import { DatabaseService } from '../database';
import { Logger, LoggerService } from '../logger';
import { AudioService, AUDIO_TYPE } from './audio.service';
import { MAX_SESSIONS, MAX_WAITING, SessionContainer, SESSION_TYPE } from './sip.models';



@Injectable({ providedIn: 'root' })
export class SessionManagerService implements OnDestroy {
  private _sessions: SessionContainer[] = []; // Sessions array
  private _callInWaiting: number = 0; // Counter to keep how many calls are in waiting
  private _isCallWaitingEnabled: boolean = true;
  private _current: SessionContainer; // Current active session
  private _isDnd: boolean = false;
  private logger: Logger;

  public callClosed: Subject<string> = new Subject();
  public callEnstablished: Subject<string> = new Subject();

  public newIncomingCall: Subject<SessionContainer> = new Subject();



  constructor(
    private audioService: AudioService,
    private dbService: DatabaseService,
    private loggerService: LoggerService,
    private settingsFacade: SettingsFacade) {
    this.logger = this.loggerService.getLoggerInstance('SessionManager');
    this.settingsFacade.doNotDisturb$.subscribe((dnd: boolean) => {
      this._isDnd = dnd;
    });
    this.settingsFacade.callWaiting$.subscribe((callWaiting: boolean) => {
      this._isCallWaitingEnabled = callWaiting;
    });
  }

  ngOnDestroy(): void {
    this.logger.close();
  }


  /**
   * Create a new Session Container and return it
   * @param session Session to add in the container
   * @param incoming If it is an incoming we also have to check that we are not going over the waiting call limit
   */
  private createAndAddSessionContainer(session: SessionWrapper, incoming: boolean = false): SessionContainer {
    // The only way to add a session is via this method
    if (this._sessions.length >= MAX_SESSIONS) {
      // We went over the max number of session we will manage.
      this.logger.warn(`Cannot manage more than ${MAX_SESSIONS} sessions. Dropping the new one`);
      return null;
    }
    // if it's an incoming call AND call waiting is false AND we already have a session going
    if (incoming && !this._isCallWaitingEnabled && this._sessions.length > 0) {
      this.logger.warn(`Call waiting disabled. At least one session is in progress. Dropping call ${session.session.id}`);
      session.endCall({
        rejectCode: SIP_STATUS_CODES.BUSY_HERE
      });
      return null;
    }
    if (incoming && this._callInWaiting >= MAX_WAITING) {
      // We went over the max number of calls we can have in waiting.
      this.logger.warn(`Cannot manage more than ${MAX_WAITING} sessions in call waiting. Dropping the new one`);
      return null;
    }
    if(incoming && this._isDnd) {
      this.logger.verbose(`Do not disturb is true. Dropping call ${session.session.id}`);
      session.endCall({
        rejectCode: SIP_STATUS_CODES.BUSY_HERE
      });
      return;
    }
    const container: SessionContainer = {
      session: session,
      meta: {},
      type: SESSION_TYPE.UNDEFINED,
      changeTypeListener: new Subject(),
      callHistoryRecord: {
        date: new Date(),
        type: session.incoming ? CallHistoryRecordType.INCOMING_MISSED : CallHistoryRecordType.OUTGOING_MISSED,
        displayName: session.remoteDisplayName,
        duration: 0
      },
      updateCallHistory: (record: CallHistoryRecord) => {
        this.dbService.updateCallHistoryRecord(record).subscribe();
      },
      onCallHistoryCreated: new BehaviorSubject(false)
    };
    if(!container.callHistoryRecord.id){
      this.dbService.addCallHistoryRecord(container.callHistoryRecord).subscribe(
        (id: string) => { 
          container.callHistoryRecord.id = id;
          container.onCallHistoryCreated.next(true);
        }
      );
    }
    session.delegates = {
      onInitial: this.initialDelegate,
      onEnstablished: this.enstablishedDelegate,
      onEnstablishing: this.enstablishingDelegate,
      onTerminating: this.terminatingDelegate,
      onTerminated: this.terminatedDelegate
    };
    return container;
  }

  /**
   * Add a session to the sessions array and update counters and types if necessary
   * @param {} session Session to add to the sessions array
   * @param {} incoming Flag to determine if the session is an incoming session or an outgoing
   */
  public addSession(session: SessionWrapper, incoming: boolean = false): SessionContainer {
    const c = this.createAndAddSessionContainer(session, incoming);
    if (c) {
      // If we have a container then set it to the current (because this will be called only for outgoing)
      if (incoming) {
        this._callInWaiting++;
        c.type = SESSION_TYPE.WAITING;
        if (!this._current) this._current = c;
        this.newIncomingCall.next(c);
      } else {
        if (this._current) this._current.session.hold();
        this._current = c;
      }
      this._sessions.push(c);
      return c;
    }
  }

  /**
   * Accept a call by id
   * @param {number} id Id of the session to accept
   */
  public acceptCallById(id: string) {
    const s = this.getSessionById(id);
    if (!s) return;
    if (s.type === SESSION_TYPE.WAITING) {
      s.session.accept();
      s.type = SESSION_TYPE.ANSWERED;
      this._callInWaiting--;
      if (this._current.session.sessionId !== s.session.sessionId) {
        this.switchToSession(s.session.sessionId);
      }
    }
  }

  /**
   * End a call by id
   * @param {number} id Id of the session to end
   */
  public endCallById(id: string) {
    const s = this.getSessionById(id);
    if (!s) return;
    s.session.endCall();
    this.removeSessionById(id);
    // If we reject a call in waiting, let's deincrement the counter
    if (s.type === SESSION_TYPE.WAITING) {
      this._callInWaiting--;
    }
  }

  /**
   * Get the current session
   * @returns {SessionContainer} The current session
   */
  public get current() {
    return this._current;
  }

  /**
   * Get all session as an array
   * @returns {SessionContainer[]} Returns all sessions containers as an array
   */
  public getAllSessions() {
    return this._sessions;
  }

  /**
   * Filter the session by the type and returns a subset
   * @returns {SessionContainer[]} Returns sessions containers as an array
   */
  public getSessionsByType(type: SESSION_TYPE) {
    return this._sessions.filter((s) => s.type === type);
  }

  /**
   * Get a session by it's ID
   * @returns {SessionContainer} Returns a session container
   */
  public getSessionById(id: string): SessionContainer {
    return this._sessions.find((s: SessionContainer) => s.session.sessionId === id);
  }

  /**
   * Get the index of the session searched by id
   * @param {number} id Id of the session to search
   * @returns {number | undefined} The index of the session found
   */
  private findSessionIndexById(id: string): number | undefined {
    const idx = this._sessions.findIndex((s) => s.session.sessionId === id);
    return idx >= 0 ? idx : undefined;
  }

  /**
   * Remove a session found by it's id
   * @param {number} id Id of the session to remove
   */
  private removeSessionById(id: string) {
    const idx = this.findSessionIndexById(id);
    if (idx > -1)
      this.removeSessionByIndex(idx);
  }

  /**
   * Remove a session by it's index
   * @param {number} index Index of the session to remove
   */
  private removeSessionByIndex(index: number) {
    let session: SessionContainer[] = this._sessions.splice(index, 1);
    session[0].session.destroy();
  }

  /**
   * Switch the current session with the one found by id. If none is found return false
   * @param {number} id Id of the session to set as current
   * @returns {boolean} Returns true if the session is switched, false otherwise
   */
  public switchToSession(id: string): boolean {
    const s = this.getSessionById(id);
    if (!s) return false;
    if (this.current && this.current.type !== SESSION_TYPE.TERMINATED && !this._current.session.held)
      this._current.session.hold();
    this._current.session.mediaAttached = false;
    this._current = s;
    s.session.attachRemoteAudio(this.audioService.getAudio(AUDIO_TYPE.REMOTE));
    // TODO: Throw event that a new current is up
    return true;
  }

  /**
   * Switch the current session with the first found. If none is found return false
   * @returns {boolean} Returns true if the session is switched, false otherwise
   */
  public switchToFirstSession(): boolean {
    const s = this._sessions[0] || undefined;
    if (s) return this.switchToSession(s.session.sessionId);
  }

  /**
   * Switch the current session with the first session in waiting. If none is found return false
   * @returns {boolean} Returns true if the session is switched, false otherwise
   */
  public switchToFirstWaitingSession(): boolean {
    const s = this.getSessionsByType(SESSION_TYPE.WAITING);
    if (!s || s.length < 1) return false;
    return this.switchToSession(s[0].session.sessionId);
  }

  /**
   * Delegate to run when the session goes to the Initial state
   * @param {SessionWrapper} s Session wrapper that apply this delegate
   */
  private initialDelegate = (s: SessionWrapper): void => {
    if (s.incoming) {
      // If it's an incoming call, we are in this state until we accept the call (or reject)
      this._current ? this.audioService.play(AUDIO_TYPE.WAITING_RING, false) : this.audioService.play(AUDIO_TYPE.INCOMING_RING);
    }
  }

  /**
   * Delegate to run when the session goes to the Enstablishing state
   * @param {SessionWrapper} s Session wrapper that apply this delegate
   */
  private enstablishingDelegate = (s: SessionWrapper): void => {
    const sessionContainer = this.getSessionById(s.sessionId);
    sessionContainer.type = SESSION_TYPE.CONNECTING;
    sessionContainer.changeTypeListener.next(sessionContainer.type);
  }

  /**
   * Delegate to run when the session goes to the Enstablished state
   * @param {SessionWrapper} s Session wrapper that apply this delegate
   */
  private enstablishedDelegate = (s: SessionWrapper): void => {
    this.audioService.stopAllRinging();
    this._callInWaiting--;
    const sessionContainer = this.getSessionById(s.sessionId);
    sessionContainer.callHistoryRecord.type = sessionContainer.session.incoming ? CallHistoryRecordType.INCOMING_ANSWERED : CallHistoryRecordType.OUTGOING_ANSWERED;
    this.dbService.updateCallHistoryRecord(sessionContainer.callHistoryRecord);
    sessionContainer.type = SESSION_TYPE.ANSWERED;
    sessionContainer.changeTypeListener.next(sessionContainer.type);
    s.attachRemoteAudio(this.audioService.getAudio(AUDIO_TYPE.REMOTE));
  }

  /**
   * Delegate to run when the session goes to the Terminating state
   * @param {SessionWrapper} s Session wrapper that apply this delegate
   */
  private terminatingDelegate = (s: SessionWrapper): void => {
    // In case of rejection let's stop ringing
    this.logger.debug("%c Session terminated ", 'background: #222; color:#bada55', s.sessionId);
    const sessionContainer = this.getSessionById(s.sessionId);
    if (sessionContainer.type !== SESSION_TYPE.ANSWERED) {
      this.audioService.stopAllRinging();
      this._callInWaiting--;
    }
    sessionContainer.type = SESSION_TYPE.TERMINATED;
    sessionContainer.changeTypeListener.next(sessionContainer.type);
    setTimeout(() => {
      /**
       * The timeout is to have javascript skip a cycle.
       * For some reason this callback is called before the rejected delegate and that means that for a rejected call
       * the flags are wrong by the time we have to play the sound. This fixes it
       */
      this.callClosed.next(s.sessionId);
      this.removeSessionById(s.sessionId);
    }, 0);
  }

  /**
  * Delegate to run when the session goes to the Terminated state
  * @param {SessionWrapper} s Session wrapper that apply this delegate
  */
  private terminatedDelegate = (s: SessionWrapper): void => {
    /**
     * Get session
     * If the session is not answered, stop ringing and decrement the call in waiting
     * If we have a session, set it's type to terminated and delete it from the array
     * If we don't have any other sessions, set current to null.
     * Else check if the current is present of if it's terminated. If yes switch to some session.
     */
    this.logger.debug("%c Session terminated ", 'background: #3333ff; color:#fff', s.sessionId);
    const c = this.getSessionById(s.sessionId);
    if (c) {
      if (c.type !== SESSION_TYPE.ANSWERED) {
        this.audioService.stopAllRinging();
        this._callInWaiting--;
      }
      c.type = SESSION_TYPE.TERMINATED;
      c.changeTypeListener.next(SESSION_TYPE.TERMINATED);
      setTimeout(() => {
        /**
         * The timeout is to have javascript skip a cycle.
         * For some reason this callback is called before the rejected delegate and that means that for a rejected call
         * the flags are wrong by the time we have to play the sound. This fixes it
         */
        this.callClosed.next(c.session.sessionId);
        this.removeSessionById(s.sessionId);
      }, 0);


      if (c.session.isCanceled && c.session.isLoseRace) {
        c.callHistoryRecord.type = CallHistoryRecordType.INCOMING_ANSWERED_ELSEWHERE;
      }
      c.callHistoryRecord.duration = c.session.callDuration;
      // Update the record when closing the call
      this.dbService.updateCallHistoryRecord(c.callHistoryRecord);
    }
    if (this._sessions.length < 1) {
      this._current = null;
    } else {
      // If there is already a current call, do not switch!!
      // It happens if the remote party closes a call that is ringing or in hold
      if (!this._current || this._current.type === SESSION_TYPE.TERMINATED) {
        if (!this.switchToFirstWaitingSession())
          this.switchToFirstSession();
      }
    }
  }

  /**
   * Send DTMF tone to the current session
   * @param {string} dtmf String of the tone to send
   */
  public sendDtmf(dtmf: string) {
    // Don't send dtmf to muted or held sessions
    if (!this._current || this._current.session.held || this._current.session.muted) return;
    switch (dtmf) {
      case '0':
        this.audioService.play(AUDIO_TYPE.DTMF_0, false);
        break;
      case '1':
        this.audioService.play(AUDIO_TYPE.DTMF_1, false);
        break;
      case '2':
        this.audioService.play(AUDIO_TYPE.DTMF_2, false);
        break;
      case '3':
        this.audioService.play(AUDIO_TYPE.DTMF_3, false);
        break;
      case '4':
        this.audioService.play(AUDIO_TYPE.DTMF_4, false);
        break;
      case '5':
        this.audioService.play(AUDIO_TYPE.DTMF_5, false);
        break;
      case '6':
        this.audioService.play(AUDIO_TYPE.DTMF_6, false);
        break;
      case '7':
        this.audioService.play(AUDIO_TYPE.DTMF_7, false);
        break;
      case '8':
        this.audioService.play(AUDIO_TYPE.DTMF_8, false);
        break;
      case '9':
        this.audioService.play(AUDIO_TYPE.DTMF_9, false);
        break;
      case '#':
        this.audioService.play(AUDIO_TYPE.DTMF_POUND, false);
        break;
      case '*':
        this.audioService.play(AUDIO_TYPE.DTMF_STAR, false);
        break;
      case '':
        this.audioService.play(AUDIO_TYPE.DTMF, false);
        break;
    }
    // There is no meaning in sending a dtmf to another session other
    // than the current
    this._current.session.sendDtmf(dtmf);
  }


  // TODO: Debug method. To remove later when there is a UI to check the sessions
  public logAllSession() {
    console.debug('Fields: index, id, state, hold, mute, attached media');
    this._sessions.forEach((e, i) => {
      console.debug('Session # ', i, e.session.sessionId, e.session.sessionState, e.session.held, e.session.muted, e.session.mediaAttached);
    })
  }
}