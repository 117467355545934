import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'highlight' })
export class HightlightPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer){}

  public transform(value: string, search: string) {
    if(!value) return '';
    const searchTerm = search.toLowerCase();
    const lowerCaseValue = value.toLowerCase();
    if(!lowerCaseValue.includes(searchTerm)){
      return value;
    }
    const start = lowerCaseValue.indexOf(searchTerm);
    const end = start + searchTerm.length;
    if(start === 0) {
      return this.domSanitizer.bypassSecurityTrustHtml(`<b>${value.substring(0, searchTerm.length)}</b>${searchTerm.length < value.length ? value.substring(searchTerm.length) : ''}`);
    }
    if(start > 0 && end === value.length) {
      return this.domSanitizer.bypassSecurityTrustHtml(`${value.substring(0, start)}<b>${value.substring(start)}</b>`);
    }
    if(start > 0 && end < value.length) {
      const str =  `${value.substring(0, start)}<b>${value.substring(start, end)}</b>${value.substring(end)}`;
      return this.domSanitizer.bypassSecurityTrustHtml(str);
    }
  }
}