export interface StartCallOutput {
  contact: any;
  type: StartCallOutputType;
  number?: string;
}

export enum StartCallOutputType {
  INTERNAL_USER = 'internal user',
  INTERNAL_GROUP = 'internal group',
  EXTERNAL_VCARD = 'external vcard',
  EVENT = 'event',
  NUMBER = 'number'
}