export const LOCAL_STORAGE_TOKEN_KEY = '_vtks';

export interface loginResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  "as:client_id": string;
  userName: string;
  jwt: string;
  ".issued": string;
  ".expires": string;
}

export interface JwtStructure {
  nbf: number;
  exp: number;
  username: string;
  roles: string[];
}

// Used any in some field because i don't need that data, so i don't care about
// its model
export interface SubscriptionResponse {
  id: string;
  company_id: string;
  chargebee_customer_id: string;
  chargebee_subscription_id: string;
  billing_info: any;
  plan: any;
  addons: any[];
  status: any;
  creation_date: Date | string;
  trial_end_date: Date | string;
  activation_date: Date | string;
  next_billing_date: Date | string;
  due_invoices_count: number;
  monthly_total_cost: number;
  promotional_credit: number;
  refundable_credit: number;
  meta_data: Metadata;
  applied_coupons: any[];
}

export interface Metadata {
  plan_upgrade: { version: string };
  shop: any;
  max_dids: number;
  available_payment_methods: string[];
  pbx: {
    device_types: {
      desktop_app: number;
      csp: number;
      desk_phone: number;
      sip: number;
    };
  };
  pricing_info: { commitment_days: number; default_user_count: number };
  included_addons: IncludedAddons;
  max_users: number;
  users: { min: number; max: number };
  traffic: {
    initial_credit: number;
    outbound: { mobile: number; countries: string[]; landline: number }[];
  };
  subscription: { 'update-flow': string };
}

export interface IncludedAddons {
  call_queue?: any;
  call_recording?: any;
  headphones_rental?: any;
  phone_rental?: any;
  channel?: any;
  number_portability?: any;
  credit_topup?: any;
  fax?: any;
  public_api?: any;
  voices_professional?: any;
  did?: any;
  crm_integrations?: any;
  phone_number?: any;
  video_conference?: any;
  wifi?: any;
}

export interface PbxUser {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  enabled: boolean;
  extension_number: number;
  extension: Extension;
  outbound_config: OutboundCliConfig;
  outbound_privacy: OutboundPrivacies;
  ring_time: number;
  groups: Group[];
  failover_callflow: FailoverCallflow;
  presence_id: string;
  is_administrator: boolean;
}

export interface Extension {
  id: number;
  number: number;
}

export interface OutboundCliConfig {
  id: number;
  enabled: boolean;
  outbound_did: Did;
  available_dids: Did[];
}

export enum OutboundPrivacies {
  DEFAULT = 'DEFAULT',
  FULL = 'FULL',
  NAME = 'NAME',
  NUMBER = 'NUMBER',
  NONE = 'NONE'
}

export interface FailoverCallflow {
  id: number;
  name: string;
  level: number;
  label: string;
  callflow_type: CallflowTypes;
}

export enum CallflowTypes {
  FORWARD = 'FORWARD',
  IVR_BACK_TO_MAIN = 'IVR_BACK_TO_MAIN',
  IVR = 'IVR',
  IVR_LISTEN_INSTRUCTION = 'IVR_LISTEN_INSTRUCTION',
  PLAY_MEDIA = 'PLAY_MEDIA',
  USERS_GROUPS = 'USERS_GROUPS',
  VOICEMAIL = 'VOICEMAIL'
}

export interface Did {
  closing_callflow: {
    callflow_type: string;
    id: number;
    name: string;
  }
  hold_media: {
    file_url: string;
    name: string;
    source: string;
    ttsInfo: any;
    type: string;
    id: number
  }
  id: number;
  label: string;
  language: string;
  number: string;
  pivotCallFlowKazooId: string;
  pivotEnabled: boolean;
  timezone: string;
  temporary: boolean;
  is_temporary?: boolean;
  allowed_as_outbound: boolean;
}

export interface Group {
  extension_number: number;
  group_type: GROUP_TYPE;
  id: number;
  name: string;
  users: GroupUser[];
}

export enum GROUP_TYPE {
  STANDARD = 'GROUP',
  CASCADE = 'CASCADE',
  QUEUE = 'QUEUE'
}

export interface GroupUser {
  email: string;
  extension_number: number;
  id: number;
  first_name: string;
  last_name: string;
}

export interface Device {
  codecs: string[];
  creation_date: Date;
  enabled: boolean;
  hardware_id: string;
  id: number;
  last_usage_check: Date;
  outbound_config: OutboundCliConfig;
  outbound_did: Did;
  password: string;
  type: string;
  user: any;
  username: string;
}