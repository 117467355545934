import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BUTTON_TYPE, MainActionClasses } from './main-action-button.model';

@Component({
  selector: 'main-action-button',
  templateUrl: './main-action-button.component.html',
  styleUrls: ['./main-action-button.component.scss']
})
export class MainActionButtonComponent implements OnInit {

  public _buttonType: MainActionClasses = BUTTON_TYPE['dialer'];
  public _buttonTypeString: string = 'dialer'; // for accessibility

  @Input()
  set buttonType(v: string) {
    if (BUTTON_TYPE[v] !== undefined) {
      this._buttonType = BUTTON_TYPE[v];
      this._buttonTypeString = v;
    } else {
      this._buttonType = BUTTON_TYPE['dialer'];
      this._buttonTypeString = 'dialer'
    }
  }

  @Output() clicked: EventEmitter<null> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (BUTTON_TYPE[this.buttonType] === undefined)
      this.buttonType = 'dialer';
  }

  public buttonClicked() {
    this.clicked.emit();
  }

}
