import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../core/services/notifications';
import { AccountFacade } from '../../../account/store/facade';
import { AccountInfo, DidsWithLabel, SessionDTO } from '../../../account/store/states-models';

@Component({
  selector: 'profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit {

  public currentDid: DidsWithLabel = {
    label: "",
    number: ""
  };
  public availableOutboundsDids: DidsWithLabel[] = [];
  public username: string = "";
  public extension: string = "";
  public avatarShort: string = "";
  public isLoading: boolean = false;

  constructor(
    private accountFacade: AccountFacade,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.accountFacade.session$.subscribe((info: SessionDTO) => {
      const cdn = info.active_outbound_number.replace(/\s/, '');
      this.currentDid = info.company_did_numbers_with_labels.find((el: DidsWithLabel) => el.number === cdn);
      this.availableOutboundsDids = info.company_did_numbers_with_labels;
      this.username = `${info.user_first_name} ${info.user_last_name}`;
      this.extension = info.user_extension;
      this.avatarShort = `${info.user_first_name[0]}${info.user_last_name
        [0]}`.toUpperCase();
    });
  }

  public setOutboundNumber(did: DidsWithLabel) {
    if (did === this.currentDid) return;
    this.isLoading = true;
  }
}
