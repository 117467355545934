import { DisplayName } from '../../sipjs/models';
import { ADDRESSBOOK_TYPES } from '../addressbook';
import { PRESENCE_STATUS } from '../sip';

export interface CallHistoryRecord {
  id?: string;
  type: CallHistoryRecordType;
  displayName: DisplayName;
  duration: number;
  date: Date,
  isDeleting?: boolean;
  status?: PRESENCE_STATUS;
  addressbookType?: ADDRESSBOOK_TYPES;
  crmLogo?: string;
  crmName?: string;
  crmContactUrl?: string;
}

export enum CallHistoryRecordType {
  UNKNOWN = 'unknown',
  INCOMING_MISSED = 'incoming missed',
  INCOMING_ANSWERED = 'incoming answered',
  INCOMING_ANSWERED_ELSEWHERE = 'incoming answered elsewhere',
  OUTGOING_MISSED = 'outgoing missed',
  OUTGOING_ANSWERED = 'outgoing answered'
}