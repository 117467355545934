export interface DialerButtonValue {
  key: string;
  chars?: string;
}


export const FULL_DIALER: DialerButtonValue[] = [
  {
    key: '1'
  },
  {
    key: '2',
    chars: 'ABC'
  },
  {
    key: '3',
    chars: 'DEF'
  },
  {
    key: '4',
    chars: 'GHI'
  },
  {
    key: '5',
    chars: 'JKL'
  },
  {
    key: '6',
    chars: 'MNO'
  },
  {
    key: '7',
    chars: 'PQRS'
  },
  {
    key: '8',
    chars: 'TUV'
  },
  {
    key: '9',
    chars: 'WXYZ'
  },
  {
    key: '*'
  },
  {
    key: '0',
    chars: '+'
  },
  {
    key: '#'
  }
]