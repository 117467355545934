import { TranslateLoader } from "@ngx-translate/core";
import { Observable, Subject, from as rFrom } from "rxjs";

/**
 * Custom loader to load translation from files
 */
export class LocalLoader implements TranslateLoader {
  constructor() { }

  /**
   * This function will load the file [lang].json.
   * @param lang Name of the language file to load
   */
  public getTranslation(lang: string): Observable<Object> {
    // Because import return a promise and get translation returns an observable, rxjs.from is used to get an observable from a promise
    return rFrom(import(`../../../assets/i18n/${lang}.json`));
  }
}
