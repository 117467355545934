import {
  UserAgent,
  UserAgentOptions,
  Registerer,
  Inviter,
  RegistererState,
  InviterOptions,
  RequestPendingError,
  Invitation,
  Subscriber,
  Notification, Publisher, SubscriptionState
} from 'sip.js';
import { SipCredentials } from '../../../account/store/states-models'
import { SessionWrapper } from './session.wrapper';
import {
  NewSessionOptions,
  SimpleCallUAOptions,
  SubscriptionEventTypes,
  SubscriptionModel,
  UserAgentWrapperOptions
} from '../models';
import { Logger, LoggerService } from '../../services/logger';
import { SIP_STATUS, SessionManagerService } from '../../services';
import { AppConfig } from '../../../../environments/environment';

const REGISTRATION_EXPIRES = 300; // In seconds
const REGISTRATION_RENEW = (REGISTRATION_EXPIRES / 2 ) * 1000 // In milliseconds
const RECONNECTION_ATTEMPTS = 3;
const RECONNECTION_DELAY = 4;

export class UAWrapper {

  private loggerService: LoggerService;
  private logger: Logger;

  private sessionManagerService: SessionManagerService; // needed to add a new session when an invite arrive.

  private userAgent: UserAgent;
  private registerer: Registerer;
  private subscriptions: SubscriptionModel[] = [];
  private publishers: Publisher[] = [];
  // Sip credentials used to initialize this user agent.
  // Saved in case it is needed for other operations
  private sipCredentials: SipCredentials;
  private renewRegistrationInterval;
  private attemptingReconnection = false;
  private shouldBeConnected = true;
  private transportconnected: boolean = false;
  private registering = false;

  // internalState
  private internalState: SIP_STATUS = SIP_STATUS.OFFLINE;

  private _delegates = {
    onConnect: null,
    onDisconnect: null,
    onRegistered: null,
    onFailedToReconnect: null,
    onReconnecting: null
  };


  /**
   * Constructor that will create the User Agent and start the connection.
   * @param {SipCredentials} sipCredentials Sip credentials used to create the user agent
   */
  public constructor(sipCredentials: SipCredentials, loggerService: LoggerService, sessionManagerService: SessionManagerService, options?: UserAgentWrapperOptions) {

    // Logger service is passed as parameter because it can't be injected from angular
    // This is not a component / service / directive

    this.loggerService = loggerService;
    this.sessionManagerService = sessionManagerService;
    this.logger = loggerService.getLoggerInstance('UserAgentWrapper');

    options = options || {};
    if (!options.kazooVersion) options.kazooVersion = 4;

    if (!sipCredentials)
      throw new Error('SipCredentials must not be null');
    this.sipCredentials = sipCredentials;
    const uri = UserAgent.makeURI(`sip:${sipCredentials.username}@${sipCredentials.domain}`);

    // Adding protocol to values in server list
    // for now wss is not supported on kazoo in both test and prod
    let wssServers = sipCredentials.wssServerList.map((value: string) => {
      return `wss://${value}`;
    });

    // Reconnect doesn't trigger onConnect but we need the delegate to run again to notify the app
    // that we are connected again. So i need to store the delegates
    this._delegates = {
      onConnect: options.onConnectDelegate,
      onDisconnect: options.onDisconnectDelegate,
      onRegistered: options.onRegistered,
      onFailedToReconnect: options.onFailedToReconnectDelegate,
      onReconnecting: options.onReconnectingDelegate
    };

    // User Agent options
    const UAOptions: UserAgentOptions = {
      uri: uri,
      authorizationUsername: sipCredentials.username,
      authorizationPassword: sipCredentials.password,
      userAgentString: 'Voxloud Desktop App',
      //TODO: Change display name when we get this data from the subscription
      displayName: options.displayName || sipCredentials.username,
      transportOptions: {
        server: wssServers[0]
      },
      delegate: {
        onConnect: () => {
          this.logger.debug('Connected')
          this.resetState();
          this.transportconnected = true;
          this.register();
          if (options.onConnectDelegate)
            options.onConnectDelegate();
        },
        onDisconnect: (error?: Error) => {
          this.logger.debug('Disconnected');
          this.resetState();
          this.transportconnected = false;
          this.unregister();
          if (options.onDisconnectDelegate) {
            options.onDisconnectDelegate();
          }
          this.internalState = SIP_STATUS.OFFLINE;
          // If error is present then the connection dropped
          if (error) {
            this.attemptReconnection();
          }
        },
        onInvite: (invitation: Invitation) => {
          // We can ring when an invite arrives using the delegate
          // And we can accept it from the session manager later on
          // TODO: Add this session (invitation) to the session manager
          // DEBUG: add this session to window
          if (options.onInviteDelegate)
            options.onInviteDelegate(invitation);
        }
      }
    };

    this.userAgent = new UserAgent(UAOptions);
    this.registerer = new Registerer(this.userAgent, {
      expires: REGISTRATION_EXPIRES
    });
    this.registerer.stateChange.addListener((data) => {
      this.logger.verbose('Registration state: ', data);
      if(data === RegistererState.Registered) {
        if(this._delegates.onRegistered) this._delegates.onRegistered();
      }
    })
    // start the user agent
    this.userAgent.start().then(() => {
      this.logger.verbose('User Agent started');
    }).catch((error) => {
      this.logger.error('Failed to connect', error.message);
      throw error;
    });

    //Monitor when the network return online
    window.addEventListener('online', () => {
      this.attemptReconnection();
    });

    window.addEventListener('offline', () => {
      this.internalState = SIP_STATUS.OFFLINE;
      this.transportconnected = false;
      this._delegates.onDisconnect();
    });
  }

  public forceReconnection(): void {
    this.attemptReconnection(RECONNECTION_ATTEMPTS);
  }

  /**
   * Register the user agent to the pbx server
   */
  public register() {
    if (this.registering || !this.transportconnected) {
      // Avoid trying registering if the process is already going (will generate errors)
      return;
    }
    this.registering = true;
    // There is an interface for the request but seems like it's not exposed
    this.registerer.register().then((request: any) => {
      this.registering = false;
      this.logger.verbose('Successfully sent REGISTER');
      this.logger.verbose('Sent request: ', request.message);
      this.setupRenewRegistration();
    }).catch((error: any) => {
      if (error instanceof RequestPendingError) {
        // Ingoring request pending error. It happens the first time a request goes timeout when the network fails
        return;
      }
      this.registering = false;
      this.logger.error("Failed to send REGISTER", error);
      throw error;
    });
  }

  /**
   * Unregister the user agent from the pbx server
   */
  public unregister() {
    if(this.registrationState === RegistererState.Terminated) return; 
    // There is an interface for the request but seems like it's not exposed
    this.registerer.unregister().then((request: any) => {
      this.logger.verbose('Successfully sent un-REGISTER');
      this.logger.verbose('Sent request: ', request.message);
      this.clearRenewRegistration();
    }).catch((error: any) => {
      this.logger.error("Failed to send un-REGISTER", error);
      throw error;
    })
  }

  /**
   * Return the current state of the registration.
   * @returns {RegistererState} State of the registration
   */
  public get registrationState(): RegistererState {
    return this.registerer.state;
  }

  /**
   * Generate a new session with the callee (target).
   * @param {string} callee Sip address or phone number of the callee
   * @param { NewSessionOptions } options Options for the new session
   */
  public getNewSession(callee: string, options?: NewSessionOptions) {
    const _options: NewSessionOptions = options || {};
    // If callee is not a sip URI, make it a sip URI
    const regex = new RegExp('^sip:');
    if (!regex.test(callee)) {
      callee = `sip:${callee}@${this.sipCredentials.domain}`;
    }
    const uri = UserAgent.makeURI(callee);
    if (!uri) {
      throw new Error("Failed creating target URI: " + JSON.stringify(callee));
    }

    const inviterOptions: InviterOptions = {
      earlyMedia: _options.earlyMedia || true,
      sessionDescriptionHandlerOptions: {
        constraints: _options.constraints
      },
      delegate: options?.delegates
    }

    const session = new Inviter(this.userAgent, uri, inviterOptions);
    if (!session) {
      throw new Error('Error creating the session');
    }
    return new SessionWrapper(session, this.loggerService);
  }

  /**
   * Create a new subscription to an event and stores it
   * @param {string} target Target of the subscription
   * @param {SubscriptionEventTypes} event Event to subscribe to
   * @param {(Notification) => void} delegate Callback to call when a notify arrives
   */
  public createSubscription(target: string, event: SubscriptionEventTypes, delegate: (notification: Notification) => void): Subscriber {
    let subscriptionModel: SubscriptionModel = {
      target: target,
      event: SubscriptionEventTypes.PRESENCE,
      subscription: null
    }
    if (this.subscriptions.find(s => s.target === subscriptionModel.target && s.event === subscriptionModel.event)) {
      this.logger.debug('Subscription already present');
      return;
    }
    if (!target.match(/^sip:/)) {
      target = `sip:${target}@${this.sipCredentials.domain}`;
    }
    const uri = UserAgent.makeURI(target);
    if (!uri) {
      throw new Error('Failed to create URI');
    }
    if (!event) {
      throw new Error('Cannot subscribe to none event');
    }
    const sub = new Subscriber(this.userAgent, uri, event, {
      delegate: {
        onNotify: delegate
      }
    });
    subscriptionModel.subscription = sub;
    this.subscriptions.push(subscriptionModel);
    return sub;
  }

  /**
   * This method will gracefully delete the subscription after unsubscribing from it
   * @param {string} target Target of the subscription to delete
   * @param {SubscriptionEventTypes} event Event of the subscription to delete
   */
  public deleteSubscription(target: string, event: SubscriptionEventTypes) {
    const index = this.subscriptions.findIndex(s => s.target === target && s.event === event);
    if (index > -1 && this.subscriptions[index].subscription.state === SubscriptionState.Subscribed)
      this.subscriptions[index].subscription.unsubscribe();
    if (index > -1) this.subscriptions.splice(index, 1);
  }

  /**
   * Create a new publisher and store it
   * @param target Target of the publisher
   * @param event Event to publish
   */
  public createPublisher(target: string, event: string): Publisher {
    const uri = UserAgent.makeURI(target);
    if (!uri) {
      throw new Error('Failed to create URI');
    }
    if (!event) {
      throw new Error('Cannot publish to none event');
    }
    const pub = new Publisher(this.userAgent, uri, event, { unpublishOnClose: true });
    this.publishers.push(pub);
    return pub;
  }

  /**
   * Broadcast message to all publisher's targets
   * @param {string} message Message to publish
   */
  public publishToAll(message: string) {
    this.publishers.forEach((p: Publisher) => {
      p.publish(message).then(() => {
        this.logger.verbose('Successfully published message');
      }).catch((reason: any) => {
        this.logger.error('Failed to publish message', reason);
      })
    })
  }

  /**
   * Setup the interval for the renew of the registration
   * @private
   */
  private setupRenewRegistration() {
    if (this.renewRegistrationInterval)
      return;
    this.renewRegistrationInterval = setInterval(() => {
      this.logger.verbose('Re-registration');
      this.register();
    }, REGISTRATION_RENEW);
  }

  /**
   * Clear the interval for the registration
   * @private
   */
  private clearRenewRegistration() {
    if (!this.renewRegistrationInterval)
      return;
    clearInterval(this.renewRegistrationInterval);
  }

  /**
   * Attempt to reconnect
   * @private
   * @param {number} reconnectionAttempt Number of the current reconnection attempts
   */
  private attemptReconnection(reconnectionAttempt = 1) {
    this.logger.debug('Attemping reconnection');
    if (!this.shouldBeConnected) {
      return;
    }
    // Avoid trying reconnecting while already trying
    if (this.attemptingReconnection) {
      return;
    }

    // Maximum amount of reconnections reached
    if (reconnectionAttempt > RECONNECTION_ATTEMPTS) {
      if(this._delegates.onFailedToReconnect) this._delegates.onFailedToReconnect();
      this.internalState = SIP_STATUS.FAILED_TO_RECONNECT;
      return;
    };

    this.attemptingReconnection = true;
    this.internalState = SIP_STATUS.RECONNECTING;
    if(this._delegates.onReconnecting) this._delegates.onReconnecting();

    setTimeout(() => {
      if (!this.shouldBeConnected) {
        this.attemptingReconnection = false;
        return;
      }
      // Attempt reconnect
      this.userAgent.reconnect().then(() => {
        // Reconnection succeded
        this.logger.debug('Reconnected');
        this.register();
        this.attemptingReconnection = false;
        if(this._delegates.onConnect)
          this.internalState = SIP_STATUS.ONLINE;
          this._delegates.onConnect();
      }).catch((error: Error) => {
        // Reconnection failed
        this.logger.debug('Reconnection attempt %d failed!', reconnectionAttempt);
        this.attemptingReconnection = false;
        this.attemptReconnection(++reconnectionAttempt);
      });
    }, reconnectionAttempt === 1 ? 0 : RECONNECTION_DELAY * 1000);
  }

  /**
   * Easy method to make a call
   * @param {string} target Target sip address or number to call
   * @param { SimpleCallUAOptions} options Optional parameter with options for the session / call
   * @returns {SessionWrapper} Returns the newly created session
   */
  public call(target: string, options?: SimpleCallUAOptions): SessionWrapper {
    const session = this.getNewSession(target, options?.newSessionOptions);
    session.call(options?.simpleSessionCallOptions);
    return session;
  }

  /**
   * Get the transport connected
   */
  public get transportConnected(): boolean {
    return this.transportconnected;
  }

  /**
   * Get the internal status
   */
  public get status(): SIP_STATUS {
    return this.internalState;
  }

  private resetState() {
    this.registering = false;
  }

  /**
   * Clean the memory
   */
  public destroy() {
    clearInterval(this.renewRegistrationInterval);
    this.userAgent.stop().then(() => {
      this.logger.info(`User agent closed successfully`);
      this.logger.close();
    });
  }
}