import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger, LoggerService } from '../services/logger';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor, OnDestroy {
  private logger: Logger;
  constructor(loggerService: LoggerService) {
    this.logger = loggerService.getLoggerInstance('HttpErrorInterceptor');
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
      this.logger.error(error);
      return throwError(error);
    }));
  }

  ngOnDestroy(): void {
    this.logger.close();
  }
}