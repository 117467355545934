import 'reflect-metadata';
import '../polyfills';

import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalLoader } from './core/i18n'

import { HomeModule } from './home/home.module';

import { LaddaModule } from 'angular2-ladda';

import { AppComponent } from './app.component';
import { HttpErrorsInterceptor, HttpTokensInterceptor } from './core/interceptors';
import { GlobalErrorHandler } from './core/services/error-handler';
import { NgVoxloudModule } from 'ng-voxloud';
 import '@angular/common/locales/global/it';
import { IncomingCallComponent } from './windows/incoming-call/incoming-call.component';
import { IntercomModule } from 'ng-intercom';
import { AppConfig } from '../environments/environment';

// AoT requires an exported function for factories
export function LocalLoaderFactory(): LocalLoader {
  return new LocalLoader();
}

@NgModule({
  declarations: [AppComponent, IncomingCallComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    NgVoxloudModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LocalLoaderFactory
      }
    }),
    IntercomModule.forRoot({
      appId: AppConfig.INTERCOM_ID,
      updateOnRouterChange: true
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokensInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
