import { Injectable } from '@angular/core';
import { AccountFacade } from '../../../account/store/facade';
import { AppConfig } from '../../../../environments/environment';
import { take } from 'rxjs/operators';
import { ElectronService } from '../electron/electron.service';
import { Logger } from './logger.class';
import { ERROR_LEVELS } from './logger.model';


@Injectable({ providedIn: 'root' })
export class LoggerService {
  private electronLogger;

  constructor(private electronService: ElectronService, private accountFacade: AccountFacade) {
    if (this.electronService.isElectron) {
      this.electronLogger = electronService.remote.require('./electron-utils/logger');
    }
  }

  /**
   * Factory to get Logger instances
   * @param {string} componentName Component name
   * @param {ERROR_LEVELS} errorLevel Max error level
   * @returns {Logger} Returns a logger instance
   */
  getLoggerInstance(componentName: string, errorLevel?: ERROR_LEVELS): Logger | undefined {
    if (!errorLevel) errorLevel = <ERROR_LEVELS>AppConfig.defaultErrorLevel;
    if (this.electronService.isElectron) {
      const winstonLogger = this.electronLogger.getLogger({ component_name: componentName, level: errorLevel });
      return new Logger(componentName, errorLevel, winstonLogger);
    }
    return new Logger(componentName, errorLevel);
  }

  /**
   * Send logs to the server. It attach the account username (email).
   */
  sendLogToServer(): void {
    if (this.electronService.isElectron) {
      this.accountFacade.username$.pipe(take(1)).subscribe(account => {
        this.electronLogger.uploadLogs(account).then(console.log);
      });
    }
  }
}