import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserNotificationService {

  // Check if Notification API is available on current browser.
  private _isNotificationAvailable: boolean = !!(window.Notification && Notification);

  constructor() {

  }

  public get isNotificationAvailable() {
    return this._isNotificationAvailable;
  }

  /**
   * Send a browser notification (invasive)
   * @param {string} title Title of the notification
   * @param {NotificationOptions} options Options for the notification
   */
  public notify(title: string, options?: NotificationOptions): Notification {
    let n: Notification = null;
    if (this._isNotificationAvailable && Notification.permission === 'granted') {
      n = new Notification(title, { body: options?.body });
    }
    else if (this._isNotificationAvailable && Notification.permission === 'default') {
      this.askForPermission();
    }
    return n;
  }

  /**
   * Ask for permission to send notifications (for browser, electron should already be granted)
   * @param {(permission: string) => {}} callback A callback for when asking the permission
   */
  public askForPermission(callback: (permission: string) => void = undefined): void {
    if (this._isNotificationAvailable && Notification.permission === 'default') {
      Notification.requestPermission().then(callback);
    }
  }
}
