import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

const MAX_HISTORY_SIZE: number = 100;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = [];
  private historyPointer: number = -1;
  private lastHistoryPointer: number = 0;
  private _canGoBack: boolean = false;
  private _canGoForward: boolean = false;
  private isLastNavigationBackOrForward: boolean = false;
  public canGoBack: BehaviorSubject<boolean> = new BehaviorSubject(this._canGoBack);
  public canGoForward: BehaviorSubject<boolean> = new BehaviorSubject(this._canGoForward);

  constructor(private location: Location, private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isLastNavigationBackOrForward) {
          this.isLastNavigationBackOrForward = false;
          return;
        }
        this.history.push(event.urlAfterRedirects);
        this._canGoBack = true;
        this._canGoForward = false;
        this.historyPointer++;
        this.canGoBack.next(this._canGoBack);
        this.canGoForward.next(this._canGoForward);
      }
    })
  }

  public back(): void {
    if (this._canGoBack) {
      this.location.back();
      this.historyPointer--;
      this.isLastNavigationBackOrForward = true;
      this._canGoForward = true;
      this._canGoBack = this.historyPointer > 0; // go back only if not at first history record
      this.canGoBack.next(this._canGoBack);
      this.canGoForward.next(this._canGoForward);
    }
  }

  public forward(): void {
    if (this._canGoForward) {
      this.location.forward();
      this.historyPointer++;
      this.isLastNavigationBackOrForward = true;
      this._canGoBack = this.historyPointer > 0;
      this._canGoForward = this.historyPointer < this.history.length - 1;
      this.canGoBack.next(this._canGoBack);
      this.canGoForward.next(this._canGoForward);
    }
  }

}
