import { createSelector, createFeatureSelector } from "@ngrx/store";
import { SettingsState } from "../../states-models";

/**
 * Select the state of the settings from the main store
 * @param state State of the main store
 */
//export const selectSettingsState = (state: State) => state.settings;
export const selectSettingsState = createFeatureSelector<SettingsState>(
  "settings"
);

/**
 * Create a selector to select the language from the settings store
 */
export const selectLanguage = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.language
);

/**
 * Create a selector to select the Audio Device Enumeration from the settings store
 */
export const selectAudioDeviceEnumeration = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.audio.audio_devices_enum
);

/**
 * Create a selector to select the Audio Input Device from the settings store
 */
export const selectAudioInputDevice = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.audio.audio_input_device
);

/**
 * Create a selector to select the Audio Output Device from the settings store
 */
export const selectAudioOutputDevice = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.audio.audio_output_device
);

/**
 * Create a selector to select the Audio Ring Device from the settings store
 */
export const selectAudioRingDevice = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.audio.audio_ring_device
);

/**
 * Create a selector to select the Audio Data Device from the settings store
 */
 export const selectAudioDataDevice = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.audio
);

/**
 * Create a selector to select the Intercom Chat Open from the settings store
 */
export const selectIntercomChatOpen = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.intercom_chat_open
);

export const selectDoNotDisturb = createSelector(
  selectSettingsState,
 (state: SettingsState) => state.doNotDisturb
);

export const selectCallWaiting = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.callWaiting
 );

 export const selectUpdateDownloaded = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.updateDownloaded
 );