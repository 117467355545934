import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Notification } from "sip.js";
import { parseStringPromise } from "xml2js";
import { SubscriptionEventTypes } from "../../sipjs/models";
import { Logger, LoggerService } from "../logger";
import { PresenceModel, PRESENCE_STATUSES_LIST } from "./sip.models";
import { SipJsStoreService } from "./sipjs.store.service";

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  private logger: Logger;

  private _presenceSubscriptions: PresenceModel[] = [];
  /**
   * Return all presence subscriptions active
   */
  public presenceSubscriptions$: BehaviorSubject<PresenceModel[]> = new BehaviorSubject(this._presenceSubscriptions);
  /**
   * Triggers when an entity change presence status
   */
  public presenceSubscription$: Subject<PresenceModel> = new Subject();

  constructor(
    private loggerService: LoggerService,
    private sipStore: SipJsStoreService
  ){
    this.logger = this.loggerService.getLoggerInstance('PresenceSubscriptionService');
  }

  public subscribeEntity(target: string) {
    if(this._presenceSubscriptions.findIndex((p: PresenceModel) => p.entity === target) > -1) return;
    const s = this.sipStore.userAgentWrapper.createSubscription(
      target,
      SubscriptionEventTypes.PRESENCE,
      this.subscribeEntityCallback
    );
    if(s) {
      s.subscribe();
    } else {
      this.logger.warn(`Failed to subscribe to presence for target: ${target}`);
    }
  }

  public unsubscribeEntity(target: string) {
    const index = this._presenceSubscriptions.findIndex((p: PresenceModel) => p.entity === target);
    if(index === -1) return;
    const ua = this.sipStore.userAgentWrapper;
    ua.deleteSubscription(target, SubscriptionEventTypes.PRESENCE);
    this._presenceSubscriptions.splice(index, 1);
  }


  public restartSubscriptions() {
    this.logger.verbose('Restarting presence subscriptions');
    const ua = this.sipStore.userAgentWrapper;
    this._presenceSubscriptions.forEach((p: PresenceModel) => {
      ua.deleteSubscription(p.entity, SubscriptionEventTypes.PRESENCE);
      ua.createSubscription(p.entity, SubscriptionEventTypes.PRESENCE, this.subscribeEntityCallback);
    });
  }


  public subscribeEntityCallback = (notification: Notification) => {
    // Send back the ACK
    const body = notification.request.body;
    parseStringPromise(body).then((parsed) => {
      // // Entity represent the topic of this presence notification
      
      const entity = parsed.presence.$.entity.split('@')[0];
      const statuses = parsed.presence.note.map(n => n._.trim().replace("\\n", ""));
      const status = function() {
        const statusesValue = {
          "Available": 1,
          "Ringing": 2,
          "On the Phone": 3
        }
        let maxStatus = statuses[0];
        statuses.forEach(s => {
          if(statusesValue[s] >= statusesValue[maxStatus])
           maxStatus = s;
          });
        return maxStatus;
      }().toLowerCase();
      this.saveAndEmit({
        entity: entity,
        status: status
      });
      notification.accept();
    });
  }

  private saveAndEmit(p: PresenceModel) {
    this.logger.warn(`${p.entity}: ${p.status}`);
    const presentIndex = this._presenceSubscriptions.findIndex((savedPresences: PresenceModel) => savedPresences.entity === p.entity);
    if(presentIndex === -1) {
      this._presenceSubscriptions.push(p);
    } else {
      this._presenceSubscriptions[presentIndex] = p;
    }
    this.presenceSubscriptions$.next(this._presenceSubscriptions);
    this.presenceSubscription$.next(p);
  }

}