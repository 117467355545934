import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../../../../shared/components/notifications/confirmation/confirmation.component';
import { AskConfirmationOptions } from '../../../../shared/components/notifications/confirmation/confirmation.model';
import { InAppNotification, Notifications, MAX_NOTIFICATIONS, NotificationsOptions, DEFAULT_TIMEOUT } from '../notification.model';

/**
 * In app notification works with toasts
 */

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements Notifications {

  public notifications: InAppNotification[] = [];

  constructor(private modalService: NgbModal){}

  /**
   * Send a notification of notify level
   * @param {string} msg Message of the notification
   * @param {NotificationsOptions} options Options of the notification
   */
  public notify(msg: string, options?: NotificationsOptions): void {
    const opts = options || {};
    const notification: InAppNotification = {
      message: msg,
      messageParameters: opts.messageParameters,
      timer: setTimeout(() => { this.remove(notification) }, opts.timeout || DEFAULT_TIMEOUT),
      classes: {
        type: 'notify',
        iconClass: opts.iconClass || ''
      }
    }
    this.add(notification);
  }

  /**
   * Send a notification of warning level
   * @param {string} msg Message of the notification
   * @param {NotificationsOptions} options Options of the notification
   */
  public warning(msg: string, options?: NotificationsOptions): void {
    const opts = options || {};
    const notification: InAppNotification = {
      message: msg,
      messageParameters: opts.messageParameters,
      timer: setTimeout(() => { this.remove(notification) }, opts.timeout || DEFAULT_TIMEOUT),
      classes: {
        type: 'warning',
        iconClass: opts.iconClass || ''
      }
    }
    this.add(notification);
  }

  /**
   * Send a notification of error level
   * @param {string} msg Message of the notification
   * @param {NotificationsOptions} options Options of the notification
   */
  public error(msg: string, options?: NotificationsOptions): void {
    const opts = options || {};
    const notification: InAppNotification = {
      //if extra.error.message exists, append after a :
      message: `${msg}${opts.extra?.error?.message ? ': ' + opts.extra?.error?.message : ''}`,
      messageParameters: opts.messageParameters,
      timer: setTimeout(() => { this.remove(notification) }, opts.timeout || DEFAULT_TIMEOUT),
      classes: {
        type: 'error',
        iconClass: opts.iconClass || ''
      }
    }
    this.add(notification);
  }

  /**
   * Send a notification of success level
   * @param {string} msg Message of the notification
   * @param {NotificationsOptions} options Options of the notification
   */
  public success(msg: string, options?: NotificationsOptions): void {
    const opts = options || {};
    const notification: InAppNotification = {
      message: msg,
      messageParameters: opts.messageParameters,
      timer: setTimeout(() => { this.remove(notification) }, opts.timeout || DEFAULT_TIMEOUT),
      classes: {
        type: 'success',
        iconClass: opts.iconClass || ''
      }
    }
    this.add(notification);
  }

  /**
   * Add the notification to the list to render checking also the length
   * @param {InAppNotification} notification Notification to be added to the list 
   */
  private add(notification: InAppNotification) {
    // if max length is reached, remove the first one (for visual reason, too much notification is no good);
    if (this.notifications.length >= MAX_NOTIFICATIONS) this.remove(this.notifications[0]);
    this.notifications.push(notification);
  }

  /**
   * Remove a notification from the list
   * @param {InAppNotification} notification Notification to remove from the list
   */
  public remove(notification: InAppNotification) {
    this.notifications = this.notifications.filter((n: InAppNotification) => n !== notification);
  }

  public askConfirmation(options?: AskConfirmationOptions, modalOptions?: NgbModalOptions): Promise<string>{
    const modalConfig: NgbModalOptions = {
      size: 'md',
      backdrop: 'static',
      centered: true,
      windowClass: 'on-top',
      backdropClass: 'on-top',
      ...modalOptions
    }
    const modalRef = this.modalService.open(ConfirmationComponent, modalConfig);
    modalRef.componentInstance.options = options;
    return modalRef.result.then(res => {
      return res
    });
  }
}