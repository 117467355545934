import { State } from "../states-models/store.state.model";
import { combineReducers, ActionReducerMap } from "@ngrx/store";
import { settingsReducer } from "./settings/settings.reducer";
import { routerReducer } from "@ngrx/router-store";

/**
 * The main store combined reducer.
 */
export const mainReducer: ActionReducerMap<State> = {
  settings: settingsReducer,
  router: routerReducer
};

// export const mainReducerMap: ActionReducerMap<State> = {
//   main: mainReducer
// }
