import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { mainReducer } from './store/reducers';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SettingsEffects } from './store/effects';
import { RouterSerializer } from './store/serializers';
import { SettingsFacade } from './store/facade';
import { InAppNotificationComponent } from './services/notifications';
import { TranslateModule } from '@ngx-translate/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppConfig } from '../../environments/environment';
import { InAppCallNotificationComponent } from '../shared/components/in-app-call-notification/in-app-call-notification.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [InAppNotificationComponent],
  imports: [
    CommonModule,
    StoreModule.forRoot(mainReducer),
    StoreRouterConnectingModule.forRoot({ serializer: RouterSerializer }),
    EffectsModule.forRoot([SettingsEffects]),
    TranslateModule,
    StoreDevtoolsModule.instrument(
      {
        name: "Desktopapp",
        maxAge: 25,
        logOnly: AppConfig.production,

      }
    ),
  ],
  providers: [SettingsFacade],
  exports: [
    InAppNotificationComponent
  ]
})
export class CoreModule { }
