
export enum ERROR_LEVELS {
  SILLY = 'silly',
  DEBUG = 'debug',
  VERBOSE = 'verbose',
  HTTP = 'http',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
};

export const ERROR_SEVERITY = {
  "silly": 6,
  "debug": 5,
  "verbose": 4,
  "http": 3,
  "info": 2,
  "warn": 1,
  "error": 0,
}

export enum CONSOLE_COLORS {
  BLACK = 'color: #000',
  BLUE = 'color: #036bfc',
  GREEN = 'color: #02bf80',
  UNSET = 'color: unset'
}