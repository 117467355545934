import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { AccountFacade } from '../../account/store/facade';
import { AccountTokens } from '../../account/store/states-models';

@Injectable()
export class HttpTokensInterceptor implements HttpInterceptor {
  constructor(private accountFacade: AccountFacade) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (!req.url.includes('api.voverc')) {
    //   return next.handle(req);
    // }
    return this.accountFacade.tokens$.pipe(
      first(),
      mergeMap((tokens: AccountTokens) => {
        if (tokens) {
          req = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${tokens.access_token}`)
              .set('Voverc-Jwt-Auth', tokens.jwt)
          })
          return next.handle(req);
        }
        return next.handle(req);
      })
    )
  }
}