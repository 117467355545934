import { Injectable, OnInit } from '@angular/core';
import { ElectronService } from '../electron';
import { IPC_CHANNELS } from '../../../../../electron-utils/electron.model';
import { ConfigModel } from '../../../../../electron-utils/electron.model';
import { Logger, LoggerService } from '../logger';
import { DefaultConfig, LOCAL_STORAGE_CONFIG_KEY } from './config.model';
import FingerprintJS from '@fingerprintjs/fingerprintjs';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private logger: Logger;

  // Keep a local config to avoid too much comunication between process which may
  // slow everything or not be in sync
  private _config: ConfigModel;

  /**
   * Returns the current config.
   */
  public get config(): ConfigModel {
    return this._config;
  }

  /**
   * Set the new config
   */
  public set config(value: ConfigModel) {
    this._config = value;
    this.logger.verbose(this._config);
    if (this.electronService.isElectron) {
      this.electronService.ipcRenderer.send(IPC_CHANNELS.UPDATE_CONFIG, this._config);
    } else {
      localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(this.config));
    }
  }

  public resetSettings(): void {
    this.config = {
      ...this.config,
      settings: DefaultConfig.settings
    };
  }

  constructor(private electronService: ElectronService, private loggerService: LoggerService) {
    this.logger = loggerService.getLoggerInstance('ConfigService');
    // Get the config the first time
    if (this.electronService.isElectron) {
      this._config = this.electronService.ipcRenderer.sendSync(IPC_CHANNELS.GET_CONFIG);
      // Update config if it changes
      this.electronService.ipcRenderer.on(IPC_CHANNELS.ON_UPDATE_CONFIG, (event: Electron.IpcRendererEvent, config: any) => {
        if(!config.env) {
          this.logger.warn(`Bad config from ${event.senderId}. Dropping`);
          return;
        };
        this._config = config;
        this.logger.verbose(this._config);
      });
    } else {
      this._config = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)) || DefaultConfig;
      this.getBrowserFingerprint().then((visitorId: string) => {
        this._config.temporary_config.machine_id = visitorId;
        this.logger.verbose(this._config);
      });
      localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(this._config));
      this.logger.verbose(this._config);
    }
  }

  /**
   * Return a promise that resolves to the visitorId
   * @returns {Promise <string>} A promise that resolve to visitorId
   */
  private async getBrowserFingerprint(): Promise<string> {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;
  }
}