import { Subscription } from "rxjs";
import { Call } from "..";

export const MAX_NOTIFICATIONS = 5;
export const DEFAULT_TIMEOUT = 5000;


export interface Notifications {
  notify(msg: string, options?: NotificationsOptions): void;
  warning(msg: string, options?: NotificationsOptions): void;
  error(msg: string, options?: NotificationsOptions): void;
  success(msg: string, options?: NotificationsOptions): void;
}

export interface NotificationsOptions {
  timeout?: number;
  iconClass?: string;
  extra?: any;
  messageParameters?: InAppNotificationMessageParameters;
}

export interface BrowserNotificationOptions { }

export interface InAppNotification {
  message: string;
  messageParameters?: InAppNotificationMessageParameters;
  timer: ReturnType<typeof setTimeout>;
  classes: NotificationClasses;
}

export interface InAppNotificationMessageParameters {
  [key: string]: any;
}

export interface NotificationClasses {
  type: string;
  iconClass?: string;
}

export interface IncomingCallNotification {
  id: number;
  call: Call;
  isInApp: boolean;
  subscription?: Subscription
}
