import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CallService } from '../../../core/services';
import { IncomingCallService } from '../../../core/services/call/incoming-call.service';
import { IncomingCallNotification } from '../../../core/services/notifications/notification.model';
import { SIP_STATUS_CODES } from '../../../core/sipjs/models';

@Component({
  selector: 'in-app-call-notification',
  templateUrl: './in-app-call-notification.component.html',
  styleUrls: ['./in-app-call-notification.component.scss']
})
export class InAppCallNotificationComponent implements OnInit, OnDestroy {

  private onIncomingNotificationSubscription: Subscription;
  public notifications: IncomingCallNotification[] = [];

  constructor(
    private incomingCallService: IncomingCallService,
    private callService: CallService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.onIncomingNotificationSubscription = this.incomingCallService.onIncomingCallNotificationAdded$.subscribe((nots: IncomingCallNotification[]) => {
      this.notifications = nots;
    });
  }

  ngOnDestroy(): void {
    if(this.onIncomingNotificationSubscription) this.onIncomingNotificationSubscription.unsubscribe();
  }

  public onReject(incoming: IncomingCallNotification): void {
    this.callService.hangup(incoming.call, {
      rejectCode: SIP_STATUS_CODES.BUSY_HERE
    });
  }

  public onAccept(incoming: IncomingCallNotification): void {
    this.callService.acceptCall(incoming.call);
  }

}
