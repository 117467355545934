import { Pipe, PipeTransform } from '@angular/core';
import { AddressbookRecord } from '../../core/services';

@Pipe({ name: 'recordFullname' })
export class RecordFullnamePipe implements PipeTransform {

  transform(record: AddressbookRecord): string {
    return record.lastName ? record.firstName + ' ' + record.lastName : record.firstName;
  }
}
